function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/home';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  cities: {
    root: '/cities',
    greeters: '/cities/greeters',
    travelers: '/cities/travelers'
  },
  countries: {
    root: '/countries',
    greeters: '/countries/greeters',
    travelers: '/countries/travelers'
  },
  destinations: '/destinations',
  experiences: '/experiences',
  interactions: '/interactions',
  users: {
    root: '/users',
    new: '/users/new',
    edit: '/users/edit/:userId'
  },
  communications: '/communications',
  greeters: {
    root: '/greeters',
    edit: '/greeters/edit',
    view: '/greeters/view'
  },
  admins: {
    root: '/admins',
    edit: '/admins/edit',
    view: '/admins/view'
  },
  travelers: {
    root: '/travelers',
    edit: '/travelers/edit',
    view: '/travelers/view'
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
